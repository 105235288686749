<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="612px" height="612px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve">
    <path d="M0,95.625v38.25h612v-48.25H0z M0,325.125h612v-48.25H0V325.125z M0,516.375h612v-48.25H0V516.375z"/>
  </svg>
</template>

<script>
export default {
  name: 'ThreeLinesIcon'
}
</script>
